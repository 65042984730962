import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    title: 'GoalMate | Reach your goals with AI mentors',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
  },
];
