import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavbarComponent } from './components/navbar/navbar.component';
import { register } from 'swiper/element';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'goalmate-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NavbarComponent, RouterOutlet],
})
export class AppComponent {
  constructor() {
    register();
  }
}
